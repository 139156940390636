import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ApiResponse } from '../models/apiresponse';
import { environment } from 'src/environments/environment';
import { Utilities } from '../shared/Utilities';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})
export class CalculatorComponent {
  loading: boolean = false;
  isBrowser: boolean;
  payingCountryConfigs: any;
  sendingCountryName: any;
  sendingCountryId: any;
  sendingCountryFlag: any;
  sendingCountryCurrencies: any;
  payingCountries: any;
  form!: FormGroup;
  selectedPayingCountry: any;
  remittanceSubTypesList: any;
  selectedRSType: any;
  selectedPayingCurrency: any = {};
  exchangeRate: any;
  sendingAmount!: any;
  calculation: any;
  ReceivingAmount!: any;

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, @Inject(PLATFORM_ID) platformId: Object, private fb: FormBuilder) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {

    this.form = this.fb.group({
      ddlSendingCountry: [null],
      txtSendingAmount: [null],
      txtReceivingAmount: [null],
      ddlPayingCountry: [null],
      ddlRemittanceType: [null],
    });

    this.loading = true;
    this.getPayingCountryConfigs();

    this.form.controls['txtSendingAmount'].valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged(), switchMap(num => of(num)))
      .subscribe(
        (value: string) => {
          if (value && +value >= 1) {
            this.sendingAmount = this.setDecimals(+value, 2);
            this.loading = true;
            this.calculateAmountAndFees(this.sendingAmount, 'send');
          }
        });

    this.form.controls['txtReceivingAmount'].valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged(), switchMap(num => of(num)))
      .subscribe(
        (value: string) => {
          if (value && +value >= 1) {
            this.ReceivingAmount = this.setDecimals(+value, 2);
            this.loading = true;
            this.calculateAmountAndFees(this.ReceivingAmount, 'pay');
          }
        });
  }

  getPayingCountryConfigs(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {

      this.loading = true;

      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const options = { headers: headers, withCredentials: true };
      const endpoint = '/remittance/getpayingcountryconfigs';
      const endPointUrl = environment.apiProtocol + '://' + environment.apiServerIP + endpoint;
      const params = {};

      this.http.get(endPointUrl)
        .toPromise()
        .then(response => {
          const jsonResponse = response;
          const apiResponse: ApiResponse = new ApiResponse(null, null);
          apiResponse.result = jsonResponse;
          resolve(apiResponse);
          this.loading = false;
          if (apiResponse.result.error) {
            alert("Error while getting paying countries");
            return;
          }
          if (apiResponse.result.payload) {
            this.payingCountryConfigs = apiResponse.result.payload.result.payingCountryConfigs;
            this.sendingCountryName = this.payingCountryConfigs[0].sendingCountryName;
            this.sendingCountryId = this.payingCountryConfigs[0].sendingCountryId;
            this.sendingCountryFlag = this.payingCountryConfigs[0].flagUrl;
            this.sendingCountryCurrencies = this.payingCountryConfigs[0].currencies[0];
            this.payingCountries = this.payingCountryConfigs[0].payingCountries;
            this.form.patchValue({
              ddlSendingCountry: this.sendingCountryId,
              ddlPayingCountry: this.payingCountries ? this.payingCountries[0].countryId : null
            });
            this.selectedPayingCountry = this.payingCountries[0];
            this.setRemittanceSubTypes();
          }
        })
        .catch((apiException: ApiResponse) => {
          this.loading = false;
          alert("Error while getting paying countries");
        });
    });
  }

  onSelectPayingCountry(event: any): void {
    this.selectedPayingCountry = this.payingCountries[event.target.selectedIndex];
    this.setRemittanceSubTypes();
    this.resetCalculation();
  }

  onSelectRSType(event: any): void {
    this.selectedRSType = this.remittanceSubTypesList[event.target.selectedIndex];
    this.setSelectedPayingCurrency();
    this.resetCalculation();
  }

  setRemittanceSubTypes() {
    this.remittanceSubTypesList = [];
    if (this.selectedPayingCountry) {
      this.selectedPayingCountry.remittanceTypes.forEach((type: { remittanceSubTypes: any; }) => {
        this.remittanceSubTypesList.push(type.remittanceSubTypes[0]);
      });
      this.form.patchValue({
        ddlRemittanceType: this.remittanceSubTypesList ? this.remittanceSubTypesList[0].remittanceSubTypeId : null
      });
      this.selectedRSType = this.remittanceSubTypesList[0];
      this.setSelectedPayingCurrency();
    }
  }

  setSelectedPayingCurrency() {
    this.selectedPayingCountry.currencies.forEach((currency: any) => {
      if (this.selectedRSType.remittanceSubTypeId == currency.remittanceSubTypeId) {
        this.selectedPayingCurrency = currency;
      }
    });
    this.calculateExchangeRate();
  }
  calculateExchangeRate() {
    this.exchangeRate = this.selectedPayingCurrency.exchangeRateUsd / this.payingCountryConfigs[0].normRate;
  }

  calculateAmountAndFees(amount: number, direction: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {

      this.loading = true;

      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const options = { headers: headers, withCredentials: false };
      const endpoint = '/remittance/getcalculatedamountandfees';
      const endPointUrl = environment.apiProtocol + '://' + environment.apiServerIP + endpoint;
      const params = { amount: amount, remittanceSubTypeId: this.selectedRSType.remittanceSubTypeId, receiverCountryId: this.selectedPayingCountry.countryId, direction: direction };

      this.http.post(endPointUrl, params, options)
        .toPromise()
        .then(response => {
          const jsonResponse = response;
          const apiResponse: ApiResponse = new ApiResponse(null, null);
          apiResponse.result = jsonResponse;
          resolve(apiResponse);
          this.loading = false;
          if (apiResponse.result.error) {
            alert("Error while getting calculations");
            return;
          }
          if (apiResponse.result.payload) {
            this.calculation = apiResponse.result.payload.result;
            if (direction == 'send') {
              this.ReceivingAmount = this.calculation.payAmountLocalCurrency;
              // this.form.patchValue({
              //   txtReceivingAmount: this.calculation.payAmountLocalCurrency
              // });
            } else {
              this.sendingAmount = this.calculation.sendAmountLocalCurrency;
              // this.form.patchValue({
              //   txtSendingAmount: this.calculation.sendAmountLocalCurrency
              // });
            }
          }
        })
        .catch((apiException: ApiResponse) => {
          this.loading = false;
          alert(apiException.error.payload.error.message);
        });
    });
  }

  resetCalculation() {
    this.calculation = null;
    this.ReceivingAmount = null;
    this.sendingAmount = null;
  }

  setDecimals(number: any, precision: number) {
    const precisionMultiplier = Math.pow(10, precision);
    number = parseFloat(number);
    return Math.round(number * precisionMultiplier) / precisionMultiplier;
  }

}
