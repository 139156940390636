export class ApiResponse {
    constructor(public error: any, public result: any) {}
  
    static fromJson({error, result}: any): ApiResponse {
      return new ApiResponse(error, result);
    }
  
    public static toJson(apiResponse: ApiResponse): ApiResponse { // do data manipulation before sending data to api
      return apiResponse;
    }
  }
  