<div class="calculator">
    <form name="calcForm" id="calcForm" class="corpCalculator" [formGroup]="form" *ngIf="form" novalidate
        autocomplete="off">
        <div class="card">
            <div class="card-body">
                <div class="you-send">
                    <h6 class="float-start">Exchange Rate 1 {{sendingCountryCurrencies?.currencyCode}} = {{exchangeRate
                        | number : '1.0-4'}} {{selectedPayingCurrency?.currencyCode}}</h6>
                    <h6 class="float-end">You Send</h6>
                    <div class="input-group mb-3">
                        <img src="{{sendingCountryFlag}}">
                        <select class="form-select ms-1" aria-label="Default select example" name="ddlSendingCountry"
                            formControlName="ddlSendingCountry">
                            <option value="{{sendingCountryId}}">{{sendingCountryCurrencies?.currencyCode}} -
                                {{sendingCountryName}}</option>
                        </select>
                        <input name="txtSendingAmount" formControlName="txtSendingAmount" type="text" placeholder="0.00"
                            class="form-control text-end" aria-label="Text input with dropdown button"
                            value="{{sendingAmount}}">
                    </div>
                </div>
            </div>
        </div>
        <div class="send-icon text-center">
            <i class="fa-solid fa-2x fa-arrow-right-arrow-left"></i>
            <div *ngIf="loading" class="spinner-border text-info" role="status"></div>
        </div>
        <div class="card mt-3">
            <div class="card-body">
                <div class="they-receive">
                    <h6 class="float-end">They Receive</h6>
                    <div class="input-group mb-3">
                        <img src="{{selectedPayingCountry?.flagUrl}}">
                        <select class="form-select ms-1" aria-label="Default select example" name="ddlPayingCountry"
                            formControlName="ddlPayingCountry" (change)="onSelectPayingCountry($event)">
                            <option value="{{pCountry.countryId}}" *ngFor="let pCountry of payingCountries">UGX -
                                {{pCountry?.countryName}}</option>
                        </select>
                        <input type="text" name="txtReceivingAmount" formControlName="txtReceivingAmount"
                            placeholder="0.00" class="form-control text-end"
                            aria-label="Text input with dropdown button" value="{{ReceivingAmount}}">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">
                <div class="card mt-3">
                    <div class="card-body">
                        <div class="remittance-type">
                            <h6 style="font-size: .8rem;">Remittance Type</h6>
                            <div class="input-group">
                                <select class="form-select" aria-label="Default select example" name="ddlRemittanceType"
                                    formControlName="ddlRemittanceType" (change)="onSelectRSType($event)">
                                    <option value="{{rSType.remittanceSubTypeId}}"
                                        *ngFor="let rSType of remittanceSubTypesList">{{rSType.remittanceSubType}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7 ps-1">
                <div class="card mt-3">
                    <div class="card-body px-0 pb-2">
                        <div class="remittance-type">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <span class="text-muted">Fees</span>
                                    <span *ngIf="calculation"
                                        class="float-end text-muted">{{calculation?.serviceFeeLocalCurrency | number :
                                        '1.2-2'}} ZAR</span>
                                    <span *ngIf="!calculation" class="float-end text-muted">-</span>
                                </li>
                                <li class="list-group-item">
                                    <span class="h6 mb-0">Total to Pay</span>
                                    <span *ngIf="calculation"
                                        class="float-end h5 mb-0">{{calculation?.totalAmountLocalCurrency | number :
                                        '1.2-2'}} ZAR</span>
                                    <span *ngIf="!calculation" class="float-end h5 mb-0"> - </span>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>