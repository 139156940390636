<!-- <div class="container-fluid bg-light">
    <div class="row">
        <div class="col-md-12 mx-auto hOnePageTitle">
            <h1 class="p-4 text-center">Sign up</h1>
        </div>
    </div>
</div> -->
<div class="container single-page banner">
    <div class="row">
        <div class="col-md-6">
            <div class="">
                <img src="assets/images/download-mobile-apps.png" alt="" class="img-fluid w-100">
            </div>
        </div>
       <div class="col-md-6 my-auto">
        <div class="banner-content stores">
            <span>Fast and safe</span>
            <h1>Send Money with our Apps</h1>
            <p>Our App is free to download. Click on the relevant button above or go to Google Play & Apple App Stores to download.</p>
                <img src="assets/images/googleplay_white.png"><span class="mx-2"></span> <img
						src="assets/images/appstore_white.png">
        </div>
    </div>
</div>